import React from 'react'
import "./style.css"
function index() {
  return (
    <div>
        <main class="term-wrap">
  <section class="term-container">
    <div class="term-container__heading">
      <h2>Terms & Conditions</h2>
    </div>
    <div class="term-container__content text-left mt-2">
     <ol>
      <li>I agree and acknowledge that all payments are <strong>NOT REFUNDABLE</strong>. All payments shall be managed by the Immigration and Citizenship Service, which will be responsible for processing the payments and providing any necessary payment support.</li>
      <li>Please contact your bank to check the status of your payment. If a payment has already been made, <em>please do not make a second payment</em>. You will be responsible for the additional payment transactions and it cannot be refunded.</li>
      <li>Applicant accept the responsibility for supplying, checking, and verifying the accuracy and correctness of the information they provide. Incorrect or inaccurate information may result in forfeiture of visa application.</li>
      <li>Please note that we do not accept and allow entry travellers <em>without approved visa</em> and applicants whose application was rejected due to <em>incorrect information.</em> </li>
      <li>Please note that if the time interval between the application date and the arrival date exceeds three months, the e-visa will not be issued.</li>
      <li>The organization is not responsible for the applicants whom do not follow the points which is stated from 1-4. </li>
     </ol>
      <ul>
      <li>People with dual nationality should choose the nationality according to the passport to be used for the travel.</li>
      <li>Note that business visa is not issued to individuals under the age of 18. Parents who come to Ethiopia with business visa along with their children can apply tourist visa for their children.</li>
       <li>Please note that we do not accept credit card payment transactions of applicants originating from the below countries effective 18 November 2020.</li>
        <p><strong>Cuba, Iraq, Sudan, Yemen, Somalia, North Korea , Afghanistan, South Sudan ,Crimea region of Ukraine,Syrian Arab Republic</strong> </p>
      </ul>
    </div>
    <div class="term-container__nav">
     
    </div>
  </section>
</main>
    </div>
  )
}

export default index